import React from "react";
import { graphql } from "gatsby";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
var lastState = "";

function BestBreweriesInternational({ data }) {
  const cityData = data.allMetrosIntlJson.edges;
  
  return (
    <Layout>
      <SEO
        title="Lists of the Best Breweries | Finding Beer"
        keywords={[`breweries`, `top breweries`, `lists`, `finding.beer`]}
      />
      <section className="bg-white border-b py-8 mt-10 sm:p-20 px-4">
        <div className="container max-w-5xl mx-auto m-8">
          <ul className="list-decimal text-sm text-center">
            <li className="inline-block mr-2"><a href="/best-breweries/" className="underline">Brewery Lists</a></li> /
            <li className="inline-block mr-2 ml-2">International Metro Areas</li>
          </ul>
          <h2 className="w-full my-2 mt-8 text-5xl font-bold leading-tight text-center text-gray-800">
            Best Breweries by International Metro Area
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          
          <div className="w-full text-sm text-center mt-8">
            <h5 className="text-xl font-bold">Jump to Country</h5>
            {quickNav(cityData)}
          </div>
        </div>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {cityData.map((item, idx) => (
            <>
            {stateHeader(item.node.meta.country)}
            <div class  ="flex justify-center">
              <a
                className="rounded-lg shadow-lg bg-white max-w-sm hover:bg-slate-100 block"
                href={`/best-breweries/international/metros/${item.node.meta.code}/`}
              >
                <div class="p-6 text-center">
                  <h5 class="text-gray-900 text-xl font-medium mb-2">{item.node.meta.city}, {item.node.meta.country}</h5>
                </div>
              </a>
            </div>
            </>
          ))}
        </div>
      </section>
    </Layout>
  );
}

function stateHeader (country) {
  if (country === lastState) return;
  lastState = country;
  return (
    <h2 id={`${country}`} className="w-full my-2 mt-8 text-3xl font-bold leading-tight text-gray-800 col-span-full">{country}</h2>
  )
}

function quickNav (data) {
  var last = '';
  var html = data.map((item, idx) => {
    if (last !== item.node.meta.country) {
      last = item.node.meta.country;
      return(<a className="inline-block mr-6 underline" href={`#${item.node.meta.country}`}>{item.node.meta.country}</a>)
    }
    
  });

  return (html);
}

export default BestBreweriesInternational;

export const query = graphql`
  query {
    allMetrosIntlJson(
      filter: {meta: {country: {ne: "United States"}}}
      sort: {fields: [meta___country, meta___city]}
    ) {
      edges {
        node {
          meta {
            city
            code
            country
            st
          }
        }
      }
    }
  }
`;
